import React, { createContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({children}) => {
	const [ open, toggle ] = useState(false);

	return (
		<ModalContext.Provider value={{
			open: open,
			toggle: toggle
		}}>
			{children}
		</ModalContext.Provider>
	)
};

export default ModalContext;
